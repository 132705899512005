import * as Sentry from '@sentry/gatsby';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import packageJson from './package.json';

Sentry.init({
  release: `${packageJson.name}@${packageJson.version}+${
    process.env.CODEBUILD_BUILD_NUMBER || 'local'
  }`,
  debug: false,
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENV,
  sampleRate: Number(process.env.SENTRY_SAMPLE_RATE) || 1,
  tracesSampleRate: Number(process.env.SENTRY_TRACES_SAMPLE_RATE) || 1,
  autoSessionTracking: process.env.SENTRY_AUTO_SESSION_TRACKING === 'true',
  integrations: [
    new TracingIntegrations.BrowserTracing({
      tracingOrigins: [
        'localhost',
        'dev.hpdevone.com',
        'staging.hpdevone.com',
        'prod.hpdevone.com',
        /.*\.execute-api\.us-east-2\.amazonaws\.com/,
        /^\//,
      ],
      // Will cause a deprecation warning, but the demise of `ignoreErrors` is still under discussion.
      // See: https://github.com/getsentry/raven-js/issues/73
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',
      ],
      denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    }),
  ],
});
